import { createBreakpoints } from '@mui/system';
import { createTheme } from '@mui/material/styles';
import { grey } from '@mui/material/colors';

import CheckSelect from 'assets/icons/CheckSelect.svg';

import MonumentExtendedRegularOTF from './assets/fonts/MonumentExtended-Regular.otf';
import AvenirNextCyrRegular from './assets/fonts/AvenirNextCyr-Regular.woff';
import AvenirNextCyrMedium from './assets/fonts/AvenirNextCyr-Medium.woff';
import AvenirNextCyrDemi from './assets/fonts/AvenirNextCyr-Demi.woff';

const customBreakpointValues = {
  values: {
    xs: 0,
    sm: 600,
    ssm: 700,
    md: 900,
    lg: 1200,
    llg: 1390,
    xl: 1600,
    xxl: 1920,
    xxl2: 2240,
    xxxl: 2560,
    xxxl2: 2816,
    xxxll: 3072,
    xxxlll: 3440,
    xxx4k: 3840,
  },
  keys: [ 'xs', 'sm', 'ssm', 'md', 'lg', 'llg', 'xl', 'xxl', 'xxl2', 'xxxl', 'xxxl2', 'xxxll', 'xxxlll', 'xxx4k' ],
};

const breakpoints = createBreakpoints({ ...customBreakpointValues });
const spacing = [ 0, 4, 8, 16, 20, 24, 32, 40, 48, 64, 80 ];

let theme = createTheme({
  spacing: spacing,
  breakpoints: {
    ...breakpoints,
  },
  palette: {
    grey: {
      main: grey[500],
      light: grey[400],
      contrastText: grey[700],
    },
    primary: {
      main: '#00BDFF',
    },
  },
  zIndex: {
    mobileStepper: 1000,
    speedDial: 1050,
    appBar: 1100,
    drawer: 1200,
    snackbar: 1300,
    modal: 1400,
    tooltip: 1500,
  },
  typography: {
    fontFamily: 'Avenir Next Cyr',
    fontSize: 15,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 600,
    useNextVariants: true,
    h1: {
      fontFamily: 'Monument Extended',
      fontSize: 40,
      fontWeight: 400,
      lineHeight: '64px',
      [breakpoints.down('xs')]: {
        fontSize: '32px',
      },
    },
    h2: {
      fontFamily: 'Monument Extended',
      fontSize: 35,
      fontWeight: 400,
      lineHeight: '48px',
    },
    h3: {
      fontFamily: 'Monument Extended',
      fontSize: 31,
      fontWeight: 400,
      lineHeight: '48px',
    },
    h4: {
      fontFamily: 'Monument Extended',
      fontSize: 28,
      fontWeight: 400,
      lineHeight: '48px',
      [breakpoints.down('xs')]: {
        fontSize: '24px',
      },
    },
    h5: {
      fontFamily: 'Monument Extended',
      fontSize: 20,
      fontWeight: 400,
      lineHeight: '32px',
    },
    subtitle1: {
      fontSize: 18,
      lineHeight: '28px',
    },
    subtitle2: {
      fontSize: 16,
      lineHeight: '24px',
      '&.subtitle3': {
        fontSize: 14,
        lineHeight: '20px',
        letterSpacing: '0.11em',
      },
      '&.subtitle4': {
        fontSize: 16,
        fontWeight: 600,
        lineHeight: '22px',
      },
      '&.subtitle5': {
        fontSize: 20,
        fontWeight: 600,
        lineHeight: '30px',
      },
      '&.subtitle6': {
        fontFamily: 'Monument Extended',
        fontSize: 16,
        fontWeight: 400,
        lineHeight: '30px',
      },
      '&.subtitle7': {
        fontSize: 20,
        fontWeight: 400,
        lineHeight: '18px',
      },
      '&.subtitle8': {
        fontSize: 24,
        fontWeight: 400,
        lineHeight: '18px',
      },
    },
    button: {
      fontWeight: 600,
      fontSize: 14,
      lineHeight: '20px',
      textTransform: 'unset',
    },
    body1: {
      fontSize: 16,
      lineHeight: '24px',
    },
    body2: {
      fontSize: 14,
      lineHeight: '20px',
      '&.body3': {
        fontWeight: 600,
        fontSize: 14,
        lineHeight: '20px',
      },
      '&.body4': {
        fontWeight: 400,
        fontSize: 14,
        lineHeight: '28px',
      },
      '&.body5': {
        fontWeight: 400,
        fontSize: 14,
        lineHeight: '28px',
      },
    },
    caption: {
      fontSize: 12,
      lineHeight: '20px',
      '&.caption1': {
        fontWeight: 600,
        fontSize: 12,
        lineHeight: '20px',
      },
      '&.caption2': {
        fontWeight: 400,
        fontSize: 12,
        lineHeight: '18px',
      },
    },
    inherit: {
      '&.subTitle': {
        marginTop: '5px',
        fontSize: '12px',
        opacity: '0.5',
      },
    },
  },
});

theme = createTheme(theme, {
  ...theme,
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Avenir Next Cyr';
          src: local('Avenir Next Cyr Regular'), local('AvenirNextCyr-Regular'),
            url(${AvenirNextCyrRegular}) format('woff');
          font-style: 'normal';
          font-weight: 400;
        }
        @font-face {
          font-family: 'Avenir Next Cyr';
          src: local('Avenir Next Cyr Medium'), local('AvenirNextCyr-Medium'), url(${AvenirNextCyrMedium}) format('woff');
          font-style: 'normal';
          font-weight: 500;
        }
        @font-face {
          font-family: 'Avenir Next Cyr';
          src: local('Avenir Next Cyr Demi'), local('AvenirNextCyr-Demi'), url(${AvenirNextCyrDemi}) format('woff');
          font-style: 'normal';
          font-weight: 600;
        }
        @font-face {
          font-family: 'Monument Extended';
          src: local('Monument Extended Regular'), local('MonumentExtended-Regular'), url(${MonumentExtendedRegularOTF}) format('opentype');
          font-style: 'normal';
          font-weight: 'normal';
        }
        body {
          font-size: 14px;
          line-height: 20px;
        }

        .greenText {
          color: #39B54A;
        }
        .redText {
          color: #ED2939;
          whiteSpace: nowrap;
        }
      `,
    },
    MuiButton: {
      styleOverrides: {
        text: {
          fontSize: 14,
          padding: '0px 24px',
        },
        sizeSmall: {
          height: '38px',
        },
        sizeLarge: {
          height: '64px',
        },
        textSizeSmall: {
          padding: '0px 32px',
          fontSize: 14,
        },
        textSizeLarge: {
          padding: '0px 32px',
          fontSize: 14,
        },
        fullWidth: {
          width: '100%',
        },

        root: {
          position: 'relative',
          width: 'auto',
          height: '48px',
          border: 'none',
          fontSize: 14,
          borderRadius: '4px',
          fontWeight: 600,
          letterSpacing: '0.05em',
          lineHeight: '17px',
          textTransform: 'unset',
          boxShadow: 'inherit',
          padding: '0 32px',
          whiteSpace: 'nowrap',
          color: 'rgba(0, 0, 0, 0.87)',

          '&:hover': {
            border: 'none',
            boxShadow: 'inherit',
          },
          '&:disabled': {
            border: 'none',
            boxShadow: 'inherit',
          },
          '&.radiusLarge': {
            borderRadius: '8px',
          },
          '&.radiusNone': {
            borderRadius: '0px',
          },
          '&.auction': {
            color: '#FFF',
            backgroundColor: '#ED2939',
            '&:hover': {
              backgroundColor: '#FBD4D7',
              color: '#ED2939',
            },
            '&:disabled': {
              color: '#909090',
              backgroundColor: '#E3E3E3',
            },
          },
          '&.transparent': {
            color: '#000',
            border: '1px solid rgba(0, 0, 0, 0.16)',
            '&:hover': {
              border: 'none',
            },
            '&:disabled': {
              color: '#909090',
              backgroundColor: '#E3E3E3',
            },
          },
          '&.white-transparent': {
            color: '#fff',
            backgroundColor: 'transparent',
            border: '1px solid rgba(255, 255, 255, 0.5)',
            '&:hover': {
              backgroundColor: 'transparent',
              color: '#fff',
            },
            '&:disabled': {
              color: '#909090',
              backgroundColor: '#E3E3E3',
            },
          },
          '&.normal': {
            color: '#FFF',
            backgroundColor: '#00BDFF',
            '&:hover': {
              backgroundColor: '#00A2DB',
              color: '#FFF',
            },
            '&:active': {
              backgroundColor: '#008ABB',
              color: '#FFF',
            },
            '&:disabled': {
              color: '#909090',
              backgroundColor: '#E3E3E3',
            },
          },
          '&.textBlack': {
            color: '#000 !important',
            '&:hover': {
              color: '#000 !important',
            },
            '&:active': {
              color: '#000 !important',
            },
            '&:disabled': {
              color: '#909090 !important',
            },
          },
          '&.black': {
            color: '#FFF',
            backgroundColor: '#000',
            '&:hover': {
              backgroundColor: '#464646',
              color: '#FFFFFF',
            },
            '&:active': {
              backgroundColor: '#757575',
              color: '#FFFFFF',
            },
            '&:disabled': {
              color: '#909090',
              backgroundColor: '#E3E3E3',
            },
          },
          '&.red': {
            color: '#FFF',
            backgroundColor: '#ED2939',
            '&:hover': {
              backgroundColor: '#CD1121',
              color: '#FFFFFF',
            },
            '&:active': {
              backgroundColor: '#A80E1B',
              color: '#FFFFFF',
            },
            '&:disabled': {
              color: '#909090',
              backgroundColor: '#E3E3E3',
            },
          },
          '&.green': {
            color: '#FFF',
            backgroundColor: '#39B54A',
            '&:hover': {
              backgroundColor: '#309A3F',
              color: '#FFFFFF',
            },
            '&:active': {
              backgroundColor: '#288235',
              color: '#FFFFFF',
            },
            '&:disabled': {
              color: '#909090',
              backgroundColor: '#E3E3E3',
            },
          },
          '&.redSquare': {
            color: '#FFF',
            backgroundColor: '#AC6343',
            '&:hover': {
              backgroundColor: '#933C17',
              color: '#FFFFFF',
            },
            '&:active': {
              backgroundColor: '#A80E1B',
              color: '#FFFFFF',
            },
            '&:disabled': {
              color: '#fff',
              backgroundColor: '#0000004d',
            },
          },
          '&.greenSquare': {
            color: '#FFF',
            backgroundColor: '#96A54A',
            '&:hover': {
              backgroundColor: '#525B20',
              color: '#FFFFFF',
            },
            '&:active': {
              backgroundColor: '#288235',
              color: '#FFFFFF',
            },
            '&:disabled': {
              color: '#fff',
              backgroundColor: '#0000004d',
              pointer: 'none',
            },
          },
          '&.filter': {
            color: '#000000',
            backgroundColor: '#FFF',
            borderRadius: 18,
            border: '1px solid #E4E6E8',
            padding: '6px 25px',
            '&:hover': {
              backgroundColor: '#FFFFFF',
              border: '1px solid #babec5',
            },
            '&:active, &.Mui-active': {
              backgroundColor: '#000000',
              color: '#FFFFFF',
            },
            '&:disabled': {
              color: '#909090',
              backgroundColor: '#E3E3E3',
            },
          },
          '&.white': {
            color: '#00BDFF',
            backgroundColor: '#FFF',
            '&:hover': {
              backgroundColor: '#CEF2FF',
              color: '#00BDFF',
            },
            '&:active': {
              backgroundColor: '#CEF2FF',
              color: '#00BDFF',
            },
            '&:disabled': {
              color: '#ACACAC',
              backgroundColor: '#FFFFFF',
            },
          },
          '&.gold': {
            color: '#AE8E41',
            backgroundColor: '#FFF',
            '&:hover': {
              backgroundColor: '#CEF2FF',
              color: '#AE8E41',
            },
            '&:active': {
              backgroundColor: '#CEF2FF',
              color: '#AE8E41',
            },
            '&:disabled': {
              color: '#ACACAC',
              backgroundColor: '#FFFFFF',
            },
          },

          '&.table': {
            height: '24px',
            borderRadius: '4px',
            color: '#00BDFF',
            border: '1px solid #CEF2FF',
            fontSize: 12,
            lineHeight: '20px',
            padding: '2px 11px',
            textAlign: 'right',
            boxSizing: 'border-box',

            '&:hover': {
              backgroundColor: '#CEF2FF',
              color: '#00BDFF',
            },
            '&:active': {
              backgroundColor: '#CEF2FF',
              color: '#00BDFF',
            },
            '&:disabled': {
              color: '#ACACAC',
              border: '1px solid #ACACAC',
              backgroundColor: 'transparent',
            },
          },
          '&.position': {
            height: '24px',
            borderRadius: '8px',
            color: '#FFF',
            fontSize: 12,
            lineHeight: '20px',
            padding: '2px 11px',
            textAlign: 'right',
            boxSizing: 'border-box',
            backdropFilter: 'blur(120px)',
            background: 'rgba(255, 255, 255, 0.2)',

            '&:hover': {
              backgroundColor: '#CEF2FF',
              color: '#00BDFF',
            },
            '&:active': {
              backgroundColor: '#CEF2FF',
              color: '#00BDFF',
            },
            '&:disabled': {
              color: '#FFF',
              background: '#babec5',
            },
          },
          '&.inputButton': {
            position: 'absolute',
            height: '32px',
            width: '50px',
            right: '8px',
            top: '8px',
            '& path': {
              fill: '#9FA4AE',
            },
          },
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: 'none',
          color: '#00BDFF',
          cursor: 'pointer',
          '&:visited': {
            color: '#00BDFF',
          },
          '&:hover': {
            color: '#00A2DB',
          },
          '&:active': {
            color: '#008ABB',
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          minHeight: 0,
          paddingTop: '4px',
          height: '34px',
          borderBottom: '1px solid #e3e3e3',
          color: '#757575',
        },
        indicator: {
          height: 0,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'capitalize',
          fontFamily: 'Monument Extended',
          fontSize: 18,
          fontWeight: 400,
          flexGrow: 1,
          maxWidth: 'none',
          minHeight: 0,
          position: 'static',
          height: '26px',
          margin: '3px 0',
          color: '#757575',
          borderBottom: '2px solid transparent',
          '&.Mui-selected': {
            color: '#000',
            borderBottom: '2px solid #000',
            '&:hover': {
              color: '#000',
              borderBottom: '2px solid #000',
            },
          },
          '&:hover': {
            color: '#464646',
            borderBottom: '2px solid #464646',
          },
          '@media screen and (max-device-width: 412px) ': {
            fontSize: 10,
            padding: '4px',
          },
          '@media screen and (max-width: 440px) ': {
            fontSize: 10,
            padding: '4px',
          },
        },
        textColorInherit: {
          '&.Mui-disabled': {
            color: '#E3E3E3',
            opacity: 1,
          },
        },
      },
    },
    MuiCircularProgress: {
      styleOverrides: {
        root: {
          '&.diagramCircle': {
            width: '300px !important',
            height: '300px !important',
            borderRadius: '10px',
          },
        },
        colorSecondary: {
          color: '#00BDFF !important',
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        colorSecondary: {
          backgroundColor: 'rgba(255,255,255, 0.2)',
        },
        barColorSecondary: {
          backgroundColor: '#FFF',
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
          width: '272px',
          height: '48px',
          '&.Mui-selected': {
            backgroundColor: '#000',
            '&:hover': {
              backgroundColor: '#000',
            },
            '&:hover span': {
              color: '#FFF',
            },
            '&:hover svg path': {
              fill: '#FFF',
            },
          },
          '&.Mui-selected span': {
            color: '#FFF',
          },
          '&.Mui-selected svg path': {
            fill: '#FFF',
          },
          '& svg path': {
            fill: '#FFF',
          },
        },
        button: {
          margin: '16px 0',
          '& .MuiTouchRipple-root span': {
            backgroundColor: '#E4E6E8',
            color: '#868C99',
          },
          '& .MuiTouchRipple-root svg path': {
            color: '#868C99',
          },
          '&:hover': {
            backgroundColor: '#FFFFFF',
          },
          '&:hover span': {
            color: '#9FA4AE',
          },
          '&:hover svg path': {
            fill: '#9FA4AE',
          },
          '& svg path': {
            fill: '#9FA4AE',
          },
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          fontWeight: 600,
          fontSize: 14,
          lineHeight: '20px',
          paddingLeft: '8px',
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: '#9FA4AE',
          display: 'inline-flex',
          minWidth: 0,
          flexShrink: 0,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
          borderColor: '#E4E6E8',
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#BABEC5',
            borderWidth: 1,
          },
          '&:focuse .MuiOutlinedInput-notchedOutline': {
            borderColor: '#BABEC5',
          },
          '&:active .MuiOutlinedInput-notchedOutline': {
            borderColor: '#BABEC5',
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: '#BABEC5',
          },
        },
        notchedOutline: {
          borderColor: '#E4E6E8',
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          //color: '#9FA4AE',
          alignItems: 'center',
          fontSize: 14,
          height: '20px',
          width: 'auto',
          justifyContent: 'center',
        },
        outlined: {
          borderRadius: '8px',
          '&.MuiSelect-outlined': {
            width: '100%',
            padding: '15px 36px 15px 20px',
          },
        },
        icon: {
          fill: '#9FA4AE',
          fontSize: '1.6rem',
        },
        iconOutlined: {
          fill: '#9FA4AE',
          color: '#9FA4AE',
          right: '8px',
          position: 'absolute',
          userSelect: 'none',
          pointerEvents: 'none',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: '0px',
        },
        body: {
          color: '#000',
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          borderRadius: '40px !important',
        },
      },
    },
    MuiTableSortLabel: {
      styleOverrides: {
        root: {
          color: '#9FA4AE',
          '&.Mui-active': {
            color: '#9FA4AE',
          },
        },
        active: {},
        icon: {
          fill: '#9FA4AE',
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          padding: '0 0 48px 0',
          '&.destinationTag' : {
            padding: '0 0 24px 0',
          },
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: 'unset',
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          padding: '40px',
          borderRadius: '8px',
          '@media screen and (max-device-width: 412px) ': {
            padding: '32px',
          },
          '@media screen and (max-width: 440px) ': {
            padding: '32px',
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          [breakpoints.up('xs')]: {
            minHeight: '48px',
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          fontSize: '14px',
          borderRadius: '8px',

          padding: '14px 16px',
          '&::placeholder': {
            textOverflow: 'ellipsis',
          },
          '&:focus': {
            boxShadow: 'unset',
          },

          '&.inputField': {
            border: '1px solid #E4E6E8',
            '&:focus': {
              border: '1px solid #BABEC5',
            },
            '&:active': {
              border: '1px solid #BABEC5',
            },
            '&:hover': {
              border: '1px solid #BABEC5',
            },
            transition: theme.transitions.create([ 'border-color', 'box-shadow' ]),
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        shrink: {
          transform: 'translate(0, 1.5px) scale(1)',
          transformOrigin: 'top left',
        },
        formControl: {
          top: '0',
          left: '0',
          position: 'absolute',
          transform: 'translate(0, 1.5px) scale(1)',
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          '&.Mui-focused': {
            color: '#000',
          },
        },
      },
    },
    MuiSkeleton: {
      styleOverrides: {
        rect: {
          borderRadius: '4px',
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: '#000',
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          borderRadius: '8px',
        },
      },
    },
    MuiSnackbarContent: {
      styleOverrides: {
        action: {},
      },
    },
    MuiSwitch: {
      styleOverrides: {
        switchBase: {
          '&.Mui-checked': {
            color: '#00BDFF',
          },
          '&.Mui-disabled': {
            color: '#BDBDBD',
          },
          '&.Mui-checked.Mui-disabled': {
            color: '#CEF2FF',
          },
        },
        track: {
          '.Mui-checked.Mui-checked + &': {
            opacity: 1,
            backgroundColor: '#00BDFF',
          },
          '.Mui-checked.Mui-disabled + &': {
            opacity: 0.3,
            backgroundColor: '#00BDFF',
          },
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        regular: {
          minHeight: '48px',
          '@media (min-width: 600px)': {
            minHeight: '48px',
          },
        },
      },
    },
    MuiGrid: {
      styleOverrides: {
        root: {
          '&.blockAssets': {
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
            width: '100%',
            '&>div': {
              marginRight: spacing[4],
              marginBottom: spacing[4],
            },
          },
          '&.title': {
            paddingBottom: spacing[6],
          },
          '&.footer': {
            width: '400px',
            marginTop: '30px',
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          '&.selectMinPaper': {
            borderRadius: '8px',
            margin: 0,
            padding: 0,
            boxShadow: 'none',
            border: '1px solid #E4E6E8',
          },
          '&.deposit' : {
            maxWidth: '700px',
          },
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          '&.selectMinList': {
            paddingTop: 0,
            paddingBottom: 0,
            background: '#fff',
            '& li': {
              fontWeight: 400,
              paddingTop: 0,
              paddingBottom: 0,
              color: '#000',
              borderRadius: '0px',
              justifyContent: 'space-between',
            },
            '& li:last-child': {
              borderBottom: '0px solid #BABEC5',
            },
            '& li:hover': {
              background: '#F4F5F6',
              borderRadius: '0px',
            },
            '& li.Mui-selected': {
              color: '#868C99',
              background: '#F4F5F6',
              borderRadius: '0px',
              '&:after': {
                content: `url(${CheckSelect})`,
              },
            },
            '& li.Mui-selected:hover': {
              background: '#F4F5F6',
              borderRadius: '0px',
            },
            '& li.MuiListItem-button': {
              margin: 0,
            },
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: '#FFF',
          boxShadow: '0px 0px 28px rgba(0, 0, 0, 0.1)',
          color: '#868C99',
          padding: theme.spacing(3),
          borderRadius: '8px',
        },
        arrow: {
          color: '#FFF',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          '& .logoBlockchain': {
            width: '20px',
            height: '20px',
            display: 'inline-block',
            backgroundSize: '100%',
            verticalAlign: 'sub',
            borderRadius: '50%',
            marginRight: '10px',
          },
          '& .currency': {
            color: '#333',
            opacity: '0.5',
            textTransform: 'capitalize',
            marginLeft: '5px',
            height: '30px',
          },
        },
      },
    },
    MuiBreadcrumbs: {
      styleOverrides: {
        root: {
          '&.breadcrumbs': {
            marginBottom: theme.spacing(6),
            '& > ol > li': {
              height: '28px',
              alignItems: 'center',
              texDecoration: 'none',
            },
            '& p': {
              fontSize: '18px',
              lineHeight: '28px',
            },
            '@media screen and (max-device-width: 412px) ': {
              marginBottom: theme.spacing(8),
            },
            '@media screen and (max-width: 440px) ': {
              marginBottom: theme.spacing(8),
            },
          },
        },
      },
    },
  },
});

export { theme };
