export const cryptoWalletBlockColumns = [
  {
    name: 'name',
    title: 'Address label',
    width: '200px',
  },
  {
    name: 'blockchainType',
    title: 'Currency / Blockchain',
  },
  {
    name: 'walletAddress',
    title: 'Wallet address/IBAN',
  },
  {
    name: 'memo',
    title: 'Memo',
  },
  {
    name: 'destination',
    title: 'Hosted/Unhosted',
  },
  {
    name: 'status',
    title: 'Status',
  },
];

export const AddressTypes = {
  BANK: 'Bank',
  CRYPTO: 'Crypto',
};
export const OTHER_VASP = 'Other';

export const WalletMode = {
  ADD: 'add',
  VIEW: 'view',
  EDIT: 'edit',
  LABEL_EDIT: 'lableEdit',
  DELETE: 'delete',
};

export const AddressStatusView = {
  'Waiting': 'Awaiting email confirmation',
  'Confirmed': 'Active',
};

export const WalletRestrictionType = {
  EXIST_WITHDRAWAL: 'existWithdrawal',
  EXIST_WITHDRAWAL_MEMO: 'existWithdrawalMemo',
  EXIST_INCOMING: 'existIncoming',
  EXIST_INCOMING_WITH_PROOF: 'existIncomingWithProof',
  NOT_EXIST: 'notExist',
};

export const TypeConfirmUrl = {
  BANK: 'bank-detail',
  CRYPTO:'crypto-wallet',
};
