import { withStyles } from 'tss-react/mui';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { CssBaseline, Paper } from '@mui/material';

import { ErrorDialog, Spinner } from 'ui';
import { sendTokenAddressLoadingSelector } from 'features/withdrawal-addresses/selectors';
import { resendTokenToConfirmationAddress, sendTokenToConfirmationAddress } from 'features/withdrawal-addresses/effects';
import { StatusSignUp } from 'features/auth/constants';

import { AddressConfirmed } from './address-confirmed';

const styles = theme => ({
  main: {
    width: '100%',
    minHeight: '100vh',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    marginLeft: 'auto',
    marginRight: 'auto',
    backgroundColor: '#F4F4F4',
  },
  container: {
    width: '100%',
    display: 'flex',
    flex: '1',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  paperSignIn: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: theme.spacing(7),
    boxShadow: 'inherit',
    borderRadius: '8px',
    backgroundColor: '#25272B',
    color: '#FFF',
  },
  titlCode: {
    fontWeight: 600,
    fontSize: '26px',
    fontHeight: '32px',
    marginBottom: '74px',
  },
  form: {
    width: '100%',
  },
  titleSignIn: {
    textAlign: 'center',
    color: '#fff',
    paddingBottom: theme.spacing(8),
  },
  formControlSignIn: {
    paddingBottom: theme.spacing(7),
  },
  formButton: {
    marginBottom: theme.spacing(8),
  },
  inputRoot: {
    'label + &': {
      marginTop: theme.spacing(6),
    },
    '& input': {
      '&:-webkit-autofill': {
        transition: 'background-color 50000s ease-in-out 0s, color 50000s ease-in-out 0s',
      },
      '&:-webkit-autofill:focus': {
        transition: 'background-color 50000s ease-in-out 0s, color 50000s ease-in-out 0s',
      },
      '&:-webkit-autofill:hover': {
        transition: 'background-color 50000s ease-in-out 0s, color 50000s ease-in-out 0s',
      },
    },
  },
  inputField: {
    fontSize: 14,
    width: '100%',
    borderRadius: '8px',
    background: '#393C43',
    color: '#fff',
    transition: theme.transitions.create([ 'border-color', 'box-shadow' ]),
    '&::placeholder': {
      textOverflow: 'ellipsis',
      color: '#9FA4AE',
    },
    '&:focus': {
      borderColor: '#9FA4AE',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
      background: '#393C43',
    },
  },
  inputLabel: {
    color: '#fff',
    marginBottom: theme.spacing(2),
    '&.Mui-focused': {
      color: '#FFF',
    },
  },
  subTitle: {
    color: '#9FA4AE',
  },
  marginRight: {
    marginRight: theme.spacing(3),
  },
});

const mapDispatchToProps = dispatch => ({
  sendToken: (typeConfirm, token) => dispatch(sendTokenToConfirmationAddress, typeConfirm, token),
  resendToken: id => dispatch(resendTokenToConfirmationAddress, id),
});

const mapStateToProps = state => ({
  loading: sendTokenAddressLoadingSelector(state) === 1,
});
function ConfirmWithdrawalAddressPageview({ classes, sendToken, resendToken, loading }) {
  const navigate = useNavigate();
  const { token } = useParams();
  const { pathname } = useLocation();
  const [ errors, setErrors ] = useState({});
  const [ errorShow, setErrorShow ] = useState(false);
  const [ status, setStatus ] = useState('');
  const [ typeConfirm, setTypeConfirm ] = useState('');

  const statusSendToken = useCallback(async () => {
    const typeConfirm = pathname.split('/')[ 2 ];
    setTypeConfirm(typeConfirm);
    const { ok, error } = await sendToken(typeConfirm, token);
    if (ok) {
      setStatus(StatusSignUp.CONFIRM);
    } else {
      setErrors(error.data);
      setErrorShow(true);
    }
  }, [ sendToken, token, pathname ]);

  useEffect(() => {
    if (token) {
      statusSendToken();
    }
  }, [ statusSendToken, token ]);

  if (loading) {
    return <Spinner />;
  }

  return (
    <main className={classes.main}>
      <CssBaseline />
      <div className={classes.container}>
        <Paper className={classes.paperSignIn}>
          {(status === StatusSignUp.CONFIRM) && (
            <AddressConfirmed classes={classes} navigate={navigate} typeConfirm={typeConfirm} />
          )}
          <ErrorDialog closeText='Go to withdrawal address page' dialogTitle='Address Confirmation Error' handleClose={() => navigate('/account/addresses')} message={errors} show={errorShow} titleVariant='h5' />
        </Paper>
      </div>
    </main>
  );
}

ConfirmWithdrawalAddressPageview.propTypes = {
  classes: PropTypes.object.isRequired,
};
export const ConfirmWithdrawalAddressPage = connect(mapStateToProps, mapDispatchToProps)(withStyles(ConfirmWithdrawalAddressPageview, styles));
