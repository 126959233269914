export const TRN_TYPE_DEPOSIT = 'Deposit';
export const TRN_TYPE_WITHDRAW = 'Withdraw';
export const TRN_TYPE_TRANSFER = 'Transfer';
export const TRN_TYPE_DCREXREPORT = 'DCR trade';
export const TRN_TYPE_VNXLUEXREPORT = 'VNX trade';
export const TRN_TYPE_CONVERSION = 'Conversion';
export const TRN_TYPE_REFUND = 'Refund';
export const TRN_TYPE_FEE = 'Fee';
export const TRN_TYPE_BONUS = 'Bonus';
export const TRN_TYPE_COMMODITIESTRADE = 'Commodities trade';
export const TRN_TYPE_COMMODITIESREDEEM = 'Commodities redeem';

export const TRN_STATUS_COMPLETED = 'completed';
export const TRN_STATUS_PENDING_IN = 'pending_in';
export const TRN_STATUS_PENDING_OUT = 'pending_out';
export const TRN_STATUS_REFUND_PENDING = 'refund_pending';
export const TRN_STATUS_DECLINED = 'declined';
export const TRN_STATUS_REJECTED = 'rejected';
export const TRN_STATUS_CANCELED = 'canceled';
export const TRN_ASSET_DCR = 'DCR';
export const TRN_ASSET_DGR = 'DGR';
export const TRN_ASSET_VNX = 'VNX';
export const TRN_ASSET_CURRENCY = 'Currency';
export const TRN_ASSET_CRYPTO = 'Crypto';
export const TRN_DIRECTION_BUY = 'Buy';
export const TRN_DIRECTION_SELL = 'Sell';

export const TRN_ACTION_ACCEPT = 'accept';
export const TRN_ACTION_DECLINE = 'decline';
export const TRN_ACTION_DETAILS = 'details';
