import { initialFetching, createFetching } from 'symbiote-fetching';
import { createSymbiote } from 'redux-symbiote';

import { PositionTypes } from 'features/common/models';

export const initialState = {
  fetchOne: initialFetching,
  fetchClient: initialFetching,
  fetchAccount: initialFetching,
  fetchUpdateAccount: initialFetching,
  fetchClientPnL: initialFetching,
  fetchClientSymbolsMyAssets: initialFetching,
  fetchUpdating: initialFetching,
  client: null,
  symbols: [],
  position: { availFunds: 0, availVNX: 0 },
  positionCurrent: { availFunds: 0, availVNX: 0 },
  clientPnL: { PnL: 0 },
  symbolsMyAssets: [],
  symbolUsd: {},
  balances: {},
  totalBalance: 0,
};
const { ALT_COIN, CRYPTO, CURRENCY, DCR, DGR, FRT, VNX, STRUCTURED_DEPOSIT } = PositionTypes;
const symbiote = {
  fetchOne: createFetching('fetchOne'),
  fetchClient: createFetching('fetchClient'),
  fetchAccount: createFetching('fetchAccount'),
  fetchUpdateAccount: createFetching('fetchUpdateAccount'),
  fetchClientPnL: createFetching('fetchClientPnL'),
  fetchClientSymbolsMyAssets: createFetching('fetchClientSymbolsMyAssets'),
  fetchUpdating: createFetching('fetchUpdating'),
  setClient: (state, data) => ({ ...state, client: data }),
  setPosition: (state, data) => ({ ...state, position: data }),
  setPositionCurrent: (state, data) => ({ ...state, positionCurrent: data }),
  setSymbols: (state, data) => {
    const symbols = data.filter(
      value => [ ALT_COIN, CRYPTO, CURRENCY, DCR, DGR, FRT, VNX, STRUCTURED_DEPOSIT ].includes(value.type) &&
        value.parentId == null &&
        value.isSupported === true
    );

    return {
      ...state,
      symbols: symbols,
    };
  },
  setClientPnL: (state, data) => ({ ...state, clientPnL: data }),
  setSymbolsMyAssets: (state, data) => ({ ...state, symbolsMyAssets: data }),
  setSymbolUsd: (state, data) => ({ ...state, symbolUsd: data }),
  setTotalBalance: (state, data) => ({ ...state, totalBalance: data }),
  setBalances: (state, data) => ({ ...state, balances: data }),
};

export const { actions, reducer } = createSymbiote(initialState, symbiote, 'account');
