import { getDateTimeFormat } from 'lib/date';
import { PositionTypes } from 'features/common/models';

import { TransactionStatus } from './models';
import { TRN_DIRECTION_BUY, TRN_TYPE_DEPOSIT } from './consts';

const sortByDate = list => list.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());

const { CRYPTO, ALT_COIN, VNX, DGR, FRT } = PositionTypes;

const getProvideInfoCondition = (item) => {
  const cryptoWallet = item.AdditionalAttributes?.CryptoWallet;
  return cryptoWallet && item.DisplayType === TRN_TYPE_DEPOSIT
    && item.Direction === TRN_DIRECTION_BUY
    && [ CRYPTO, ALT_COIN, VNX, DGR, FRT ].includes(item.SymbolType)
    && item.Status === TransactionStatus.PENDING_IN
    && !(cryptoWallet.VaspId || cryptoWallet.ProofOfOwnership);
};

export const mapTransactions = (items) => {
  let rows = [];
  for (let item of items) {
    let row = {
      id: item.id,
      date: getDateTimeFormat(item.Date),
      type: item.Type,
      displayType: item.DisplayType,
      quantity: item.Quantity,
      asset: item.Ticker,
      status: item.Status,
      direction: item.Direction,
      hash: item.Hash || null,
      symbolId: item.SymbolId,
      symbolType: item.SymbolType || '-',
      directSaleOfferId: item.DsId || null,
      isNeedToProvideInfo: getProvideInfoCondition(item),
      walletAddress: item.AdditionalAttributes?.CryptoWallet?.Address,
      walletType: item.AdditionalAttributes?.CryptoWallet?.Type,
    };
    rows.push(row);
  }
  return rows;
};

export const mapSortTransactions = (list) => {
  if (list && list.length > 0) {
    return sortByDate(mapTransactions(list));
  }
  return [];
};
