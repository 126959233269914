import { handleFetching } from 'symbiote-fetching';

import { walletApi } from 'features/wallet/api.js';
import { accountApi } from 'features/account/api';

import { actions as actionsAccount } from '../account/symbiotes.js';

import { actions } from './symbiotes';
import { getResponse } from './response';
import { mapBlockchains, mapPositionProducts, mapSymbols } from './mappers';
import { commonApi } from './api';

export const uploadFile = (query, accessToken, appDeviceId) =>
  handleFetching(actions.fetchOne, {
    noThrow: false,
    async run(dispatch) {
      try {
        const response = await dispatch(commonApi.uploadFile, query, accessToken, appDeviceId);
        return getResponse({ status: response.status, ...(response[0] || response) });
      } catch (error) {
        return { ok: false, error: { data: String(error) } };
      }
    },
  });

export const deleteFile = name =>
  handleFetching(actions.fetchOne, {
    noThrow: false,
    async run(dispatch) {
      try {
        const response = await dispatch(commonApi.deleteFile, name);
        return getResponse(response);
      } catch (error) {
        return { ok: false, error: { data: String(error) } };
      }
    },
  });

export const getAllSymbols = query =>
  handleFetching(actions.fetchSymbols, {
    noThrow: false,
    async run(dispatch) {
      try {
        const response = await dispatch(accountApi.getSymbols, { ...query, limit: 2000 });
        const { ok, data, error } = getResponse(response);
        if (ok) {
          let symbols = mapSymbols(response.rows);
          await dispatch(actionsAccount.setSymbols(symbols));
          dispatch(actions.setAllSymbols(symbols));
        } else {
          return { ok, data, error };
        }
      } catch (error) {
        return { ok: false, error: { data: String(error) } };
      }
    },
  });

export const getPublicAllSymbols = query =>
  handleFetching(actions.fetchSymbols, {
    noThrow: false,
    async run(dispatch) {
      try {
        const response = await dispatch(accountApi.getPublicSymbols, { ...query, limit: 2000 });
        const { ok, data, error } = getResponse(response);
        if (ok) {
          let symbols = mapSymbols(response.rows);
          await dispatch(actionsAccount.setSymbols(symbols));
          await dispatch(actions.setAllSymbols(symbols));
        } else {
          return { ok, data, error };
        }
      } catch (error) {
        return { ok: false, error: { data: String(error) } };
      }
    },
  });

export const getClientPositionProductsOnly = () =>
  handleFetching(actions.fetchPositionsProductOnly, {
    noThrow: false,
    async run(dispatch) {
      try {
        const response = await dispatch(walletApi.getUIProductPositions, { ProductOnly: true });
        const { ok, data, error } = getResponse(response);
        if (ok) {
          dispatch(actions.setPositionProducts(mapPositionProducts(data.source_data)));
        } else {
          return { ok, data, error };
        }
      } catch (error) {
        return { ok: false, error: String(error) };
      }
    },
  });

export const getPublicPositionProductsOnly = () =>
  handleFetching(actions.fetchPositionsProductOnly, {
    noThrow: false,
    async run(dispatch) {
      try {
        const response = await dispatch(walletApi.getPublicProductPositions, { ProductOnly: true });
        const { ok, data, error } = getResponse(response);
        if (ok) {
          dispatch(actions.setPositionProducts(mapPositionProducts(data.source_data)));
        } else {
          return { ok, data, error };
        }
      } catch (error) {
        return { ok: false, error: String(error) };
      }
    },
  });

export const getCryptoBlockchains = () =>
  handleFetching(actions.fetchCryptoBlockchains, {
    noThrow: false,
    async run(dispatch) {
      try {
        const response = await dispatch(walletApi.getCryptoBlockchains);
        const { ok, data, error } = getResponse(response);
        let cryptoBlockchains = {};
        if (ok) {
          cryptoBlockchains = mapBlockchains(data.source_data);
          dispatch(actions.setCryptoBlockchains(cryptoBlockchains));
          return { cryptoBlockchains };
        } else {
          return { ok, data, error };
        }
      } catch (error) {
        return { ok: false, error: String(error) };
      }
    },
  });

export const getFile = name =>
  handleFetching(actions.fetchOne, {
    noThrow: false,
    async run(dispatch) {
      try {
        const response = await dispatch(commonApi.getFile, name);
        return getResponse(response);
      } catch (error) {
        return { ok: false, error: { data: String(error) } };
      }
    },
  });
