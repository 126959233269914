export const trunc = (value, n) => (value && value.length > n ? value.substr(0, n - 1) + '...' : value);

export const trimHash = (hash) => {
  if (hash && hash.length > 4) {
    return hash.slice(0, 4) + '...' + hash.slice(hash.length - 2, hash.length);
  }
  if (hash && hash.length < 4) {
    return hash;
  }
  return '';
};

export const trimWalletAddress = (walletAddress) => {
  if (walletAddress?.length > 40) {
    return walletAddress.slice(0, 10) + '...' + walletAddress.slice(walletAddress.length - 10, walletAddress.length);
  } else {
    return walletAddress;
  }
  // if (walletAddress?.length <= 42 && walletAddress?.length > 20) {
  //   return walletAddress.slice(0, 5) + '...' + walletAddress.slice(walletAddress.length - 5, walletAddress.length);
  // }
  // if (walletAddress?.length <= 20) {
  //   return walletAddress;
  // }
  // return '';
};

export const trimMemo = (memo) => {
  if (memo?.length > 10) {
    return memo.slice(0, 5) + '...' + memo.slice(memo.length - 5, memo.length);
  }
  if (memo?.length) {
    return memo;
  }

  return '';
};

export const trimApiKey = (key) => {
  if (key?.length > 42) {
    return key.slice(0, 12) + '...' + key.slice(key.length - 12, key.length);
  }
  if (key?.length <= 20) {
    return key;
  }
  return '';
};

export const trimNameWalletAddress = (name, walletAddress) => {
  if (name?.length + walletAddress?.length > 40) {
    return trimWalletAddress(name) + ' ' + trimWalletAddress(walletAddress);
  } else if (name?.length + walletAddress?.length <= 40) {
    return trimWalletAddress(name) + ' ' + trimWalletAddress(walletAddress);
  }
  return 'N/A';

  // if (name && name.length > 20) {
  //   return name.slice(0, 8) + '...' + name.slice(name.length - 6, name.length) + ' ' + trimWalletAddress(walletAddress)
  // }
  // if (name && name.length < 20) {
  //   return name + ' ' + trimWalletAddress(walletAddress)
  // }
  // return ''
};

export const trimIbanAddress = (walletAddress) => {
  if (walletAddress?.length > 20) {
    return walletAddress.slice(0, 8) + '...' + walletAddress.slice(walletAddress.length - 8, walletAddress.length);
  }
  if (walletAddress && walletAddress.length <= 20) {
    return walletAddress;
  }
  return '';
};

export const trimNameIban = (name, walletAddress) => {
  if (name?.length + walletAddress?.length > 40) {
    return trimIbanAddress(name) + ' ' + trimIbanAddress(walletAddress);
  } else if (name?.length + walletAddress?.length <= 40) {
    return trimIbanAddress(name) + ' ' + trimIbanAddress(walletAddress);
  }
  return 'N/A';
};

export const trimFilePath = (filePath) => {
  if (filePath?.length > 42) {
    return filePath.slice(0, 8) + '...' + filePath.slice(filePath.length - 8, filePath.length);
  }
  if (filePath?.length <= 42 && filePath?.length > 20) {
    return filePath.slice(0, 8) + '...' + filePath.slice(filePath.length - 8, filePath.length);
  }
  if (filePath?.length <= 20) {
    return filePath;
  }
  return '';
};
export const toCapitalize = (str) => {
  if (!str) return str;
  return str[0].toUpperCase() + str.slice(1);
};

export const isJsonString = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};
