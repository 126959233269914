import React from 'react';
import PropTypes from 'prop-types';
import { Button, Typography, Grid } from '@mui/material';

import { TypeConfirmUrl } from 'features/withdrawal-addresses/models';

function AddressConfirmedView({ classes, typeConfirm, navigate }) {
  return (
    <div>
      <Grid container alignItems="baseline" className={classes.titleSignIn} justifyContent="space-between">
        <Typography className={classes.marginRight} variant="h4">
          Withdrawal Address added
        </Typography>
      </Grid>
      <Typography className={classes.titleSignIn} variant="body2">
        You have successfully added new withdrawal address{typeConfirm === TypeConfirmUrl.CRYPTO ? ', it will be active after compliance review' : ''}
      </Typography>
      <Grid container justifyContent="center">
        <Button fullWidth className="normal" size="large" onClick={() => navigate('/account/addresses')}>
          Go to withdrawal address page
        </Button>
      </Grid>
    </div>
  );
}

AddressConfirmedView.propTypes = {
  classes: PropTypes.object.isRequired,
};

export const AddressConfirmed = AddressConfirmedView;
