import React from 'react';
import { Typography } from '@mui/material';

export const Role = {
  ISSUER: 'issuer',
  INVESTOR: 'investor',
};

export const ClientType = {
  INDIVIDUAL: 'individual',
  LEGAL: 'legal',
};

export const RoleClientType = {
  ISSUER: 'issuer',
  INVESTOR: 'marketParticipant',
  BROKER: 'broker',
};

export const NavigationPositions = {
  DASHBOARD: 'dashboard',
  WALLETS: 'wallets',
  TRANSACTIONS: 'transactions',
  OFFERINGS: 'offerings',
  STAKING: 'staking',
  INFORMATION: 'information',
  ADDRESSES: 'addresses',
  API_KEYS: 'keys',
  NOTIFICATIONS: 'notifications',
  LOGOUT: 'logout',
};

export const PositionTypes = {
  ALT_COIN: 'AltCoin',
  DGR: 'DGR',
  DCR: 'DCR',
  FRT: 'FRT',
  CURRENCY: 'Currency',
  CRYPTO: 'Crypto',
  VNX: 'VNX',
  STRUCTURED_DEPOSIT: 'StructuredDeposit',
  AGGREGATED_DEPOSIT: 'AggregatedStructuredDeposit',
  GOLD: 'GOLD',
};

export const AssetSymbols = {
  EUR: 'EUR',
  USD: 'USD',
  CHF: 'CHF',
  BTC: 'BTC',
  ETH: 'ETH',
  VNXLU: 'VNXLU',
  USDC: 'USDC',
};

export const ErrorTypes = {
  BACK_VALID: 'validation_error',
  FRONT_VALID: 'ValidationError',
  ERROR_CREATE_ORDER: 'error_create_order',
  INVALID_2FA_CODE: 'invalid_2fa_code',
  CRYPTO_RATE_EXPIRED: 'crypto_rate_expired',
  KRAKEN_RATE_EXPIRED: 'kraken_rate_expired',
  INVALID_WALLET_ADDRESS: 'invalid_wallet_address',
  JWT_EXPIRED: 'jwt expired',
  REFRESH_JWT_EXPIRED: 'refresh token. jwt expired',
  SESSION_EXPIRED: 'session_expired',
  INVALID_CREDENTIALS: 'invalid_credentials',
  TOKEN_NOT_REGISTRED: 'token not registred',
  TOKEN_RECALLED_OR_DISABLED: 'token recalled or disabled',
  MINT_NOT_ALLOWED: 'api_mint_permission_not_allowed',
};

export const errorsOrder = {
  bittrex_service_error: {
    error: 'bittrex_service_error',
    message: 'Internal error. Please contact support',
  },
  'Error create order': {
    error: 'error_create_order',
    message: 'Internal error. Please contact support',
  },
};

export const errorsCreateOrder = {
  error_create_order: {
    error: 'error_create_order',
    messages: {
      standart: 'Internal error. Please contact support',
      notEnoughtFunds: 'There are not enough funds on your account. The order can\'t be added.',
    },
  },
};

export const errorsMessagesFromBackend = {
  notEnoughtFunds: 'There are not enough funds on client\'s account. The order can\'t be added.',
};

export const blockchainsPriority = {
  ETH: 0,
  Q: 1,
};

export const currencySymbols = {
  CHF: <Typography component='span' fontFamily='sans-serif' variant='inherit'>₣</Typography>,
  EUR: <Typography component='span' variant='inherit'>€</Typography>,
  undefined: <Typography component='span' variant='inherit'>€</Typography>,
};

export const PageTitles = {
  '/join': 'Sign In',
  '/sign-up': 'Sign Up',
  '/account': 'Dashboard',
  '/account/wallet': 'Wallet',
  '/account/transactions': 'Transactions',
  '/account/information': 'My Profile',
  '/account/addresses': 'Withdrawal Addresses',
  '/account/notifications': 'Notification Center',
  '/terms/general': 'Terms',
  '/terms/frt': 'Terms',
};
