import { withStyles } from 'tss-react/mui';
import React from 'react';
import CheckboxMaterial from '@mui/material/Checkbox';

const styles = theme => ({
  checkIcon: { width: '24px', height: '24px', '&>input:disabled': {
    color: 'red',
  } },
});

const CheckboxView = (props) => {
  const { classes, staticContext, ...other } = props;
  return (
    <CheckboxMaterial
      // checkedIcon={<CheckBoxOutlinedIcon className={classes.checkIcon} />}
      color="default"
      // icon={<CheckBoxOutlineBlankOutlinedIcon className={classes.checkIcon} />}
      {...other}
    />
  );
};

export const Checkbox = withStyles(CheckboxView, styles);
