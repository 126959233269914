import { connect } from 'react-redux';
import React, { useState } from 'react';
import { Button, Grid, Link, FormHelperText, FormControl } from '@mui/material';

import { isJsonString, trimFilePath } from 'lib/string';
import { fileDownload } from 'lib/file-download';
import { getProofScreen } from 'features/withdrawal-addresses/effects';

import { uploadFile } from '../effects';
import { defaultCommonFileExtensions } from '../constants';

const mapDispatchToProps = dispatch => ({
  uploadFile: (query, accessToken, appDeviceId) => dispatch(uploadFile, query, accessToken, appDeviceId),
  getProofScreen: link => dispatch(getProofScreen, link),
});

const ImageView = ({ readOnly, helperText, handleUpload, getProofScreen, uploadFile, disabled, name }) => {
  const [ error, setError ] = useState(null);
  const [ inputValue, setInputValue ] = useState('');
  const maxSize = 10; // in MB

  const onFileLoad = async (e) => {
    const fileName = e.target.files[0]?.name;
    const fileSize = e.target.files[0]?.size / 1024 / 1024; // in MB
    const extension = fileName.slice(fileName.lastIndexOf('.') + 1).toLowerCase();

    if (fileSize > maxSize) {
      setError(`The maximum size is ${maxSize} MB`);
      setInputValue('');
    } else if (!defaultCommonFileExtensions.includes(extension)) {
      setError(`Only ${defaultCommonFileExtensions.join(', ').toUpperCase()} files are accepted`);
      setInputValue('');
    } else {
      setError(null);
      const data = new FormData();
      data.append('data', e.target.files[0]);

      let accessToken = JSON.parse(isJsonString(localStorage.getItem('accessToken')) ? localStorage.getItem('accessToken') : '""');
      let appDeviceId = JSON.parse(isJsonString(localStorage.getItem('appDeviceId')) ? localStorage.getItem('appDeviceId') : '""');

      const { ok, data: file, error: uploadError } = await uploadFile(data, accessToken, appDeviceId);

      if (ok) {
        handleUpload(file.name);
      } else {
        alert(uploadError.message || uploadError.data || 'File upload error. Please contact support');
      }
    }
  };

  const downloadDocumentItem = async (name) => {
    const { data, error, ok } = await getProofScreen(name);
    if (ok) {
      const ext = name.split('.').pop();
      fileDownload(data, name, ext);
    } else {
      setError(error.data);
    }
  };

  return (
    <FormControl error={!!error || !!helperText}>
      <input alt="Logo" disabled={readOnly} id="contained-button-file" style={{ display: 'none' }} type="file" value={inputValue} onChange={onFileLoad} />

      {name ? (
        <Grid container alignItems="center" gap="24px" wrap="nowrap">
          <Link type='button' onClick={() => downloadDocumentItem(name)}>
            {`${trimFilePath(name)}`}
          </Link>
          <Button className="normal" disabled={disabled} sx={{ maxWidth: '120px' }}>
            <label htmlFor="contained-button-file" style={{ padding: '15.5px 24px', cursor: 'pointer' }}>
              Change file
            </label>
          </Button>

        </Grid>
      ) : (
        <Button className="normal" disabled={disabled} sx={{ maxWidth: '120px' }}>
          <label htmlFor="contained-button-file" style={{ padding: '15.5px 24px', cursor: 'pointer' }}>
            Select file
          </label>
        </Button>
      )}
      <FormHelperText>{error || helperText}</FormHelperText>
    </FormControl>
  );
};

export const Image = connect(null, mapDispatchToProps)(ImageView);
