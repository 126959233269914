import { trimNameIban, trimNameWalletAddress } from 'lib/string';

import { AddressTypes } from './models';

const sortByCurrencyAndName = items =>
  items.sort((a, b) => {
    if (a.blockchainType > b.blockchainType) return 1;
    if (a.blockchainType < b.blockchainType) return -1;
    if (a.name > b.name) return 1;
    if (a.name < b.name) return -1;
    return 0;
  });

// "Type": "ETH",
// "Address": "0x474650F5FA61cf376945E3aa7b83B50e3f2506Fd",
// "Status": "Pending",
// "Data": null,
// "createdAt": "2021-12-21T09:42:10.386Z",
// "updatedAt": "2021-12-21T09:42:10.386Z",
// "ClientId": 3
export const mapWalletsData = (items) => {
  if (items?.length > 0) {
    return items.map(item => ({
      id: item.id,
      name: item?.Label || '',
      blockchainType: item?.Currency || '',
      status: item?.Status,
      walletAddress: item?.Address,
      clientId: item?.ClientId,
      concatAddress: String(trimNameWalletAddress(item?.Name, item?.Address)),
      addressType: item?.AddressType,
      vaspName: item?.VaspName,
      memo: item?.Memo || '',
    }));
  } else {
    return [];
  }
};

export const mapCryptoWallet = item => ({
  id: item.id,
  walletAddress: item?.Address,
  ticker: item.Type || '',
  otherVaspName: item.OtherVaspName || '',
  proofOfOwnership: item.ProofOfOwnership || '',
  name: item.Name || '',
  vaspId: item.VaspId,
  memo: item.Memo,
  addressType: AddressTypes.CRYPTO,
  isWithdrawal: item.Withdrawal,
});

export const mapWithdrawalAddress = item => ({
  id: item.id,
  name: item?.Label || '',
  blockchainType: item?.Currency || item.Type || '',
  status: item?.Status,
  walletAddress: item?.Address,
  clientId: item?.ClientId,
  concatAddress: String(trimNameWalletAddress(item?.Name, item?.Address)),
  addressType: item?.AddressType,
  vaspName: item?.VaspName,
  cryptoWalletType: item?.CryptoWalletType,
  currency: item?.Currency,
  isExpired: item.Resend,
  memo: item?.Memo || '',
});

export const mapWithdrawalAddressDataWithoutSort = items => items.map(item => mapWithdrawalAddress(item));

export const mapWithdrawalAddressData = (items) => {
  if (items?.length > 0) {
    return sortByCurrencyAndName(mapWithdrawalAddressDataWithoutSort(items));
  } else {
    return [];
  }
};

export const mapBankDetailWalletsData = (items) => {
  if (items?.length > 0) {
    return items.map(item => ({
      id: item.id,
      assetName: item?.BankDetail?.Symbol?.Ticker,
      assetType: item?.BankDetail?.Symbol?.Type,
      assetId: item?.BankDetail?.Symbol?.id,
      addressType: item?.AddressType,
      bankDetailId: item?.BankDetailId,
      name: item?.BankDetail?.Name,
      iban: item?.BankDetail?.Iban,
      accountNumber: item?.BankDetail?.AccountNumber,
      bic: item?.BankDetail?.Bic,
      swift: item?.BankDetail?.Swift,
      bank: item?.BankDetail?.Bank,
      bankAddress: item?.BankDetail?.BankAddress,
      beneficiary: item?.BankDetail?.Beneficiary,
      beneficiaryAddress: item?.BankDetail?.BeneficiaryAddress,
      memo: item?.BankDetail?.Memo,
      status: item.Status,
      concatAddress: String(trimNameIban(item?.BankDetail?.Name, item?.BankDetail?.Iban)),
    }));
  } else {
    return [];
  }
};

export const mapWithdrawalAddressSymbols = (items) => {
  if (items?.length > 0) {
    return items.map(item => ({
      id: item.id,
      assetName: item?.Ticker,
      assetType: item?.Type,
      addressType: item?.AddressType,
      currency: item?.Currency,
    }));
  } else {
    return [];
  }
};
// {
//   id: 74,
//   Status: 'Confirmed',
//   createdAt: '2022-02-02T12:29:12.647Z',
//   updatedAt: '2022-02-03T07:37:10.850Z',
//   ClientId: 6,
//   BankDetailId: 8,
//   BankDetail: {
//     id: 8,
//     Name: 'test 1',
//     Type: 'Out',
//     Iban: 'IBAN1',
//     AccountNumber: '',
//     Bic: 'BIC1',
//     Swift: '',
//     Bank: '',
//     BankAddress: '',
//     Beneficiary: 'roma investor last1',
//     BeneficiaryAddress: '',
//     Memo: '',
//     createdAt: '2022-02-02T12:29:12.642Z',
//     updatedAt: '2022-02-02T12:29:12.642Z',
//     SymbolId: 2,
//     Symbol: {
//       id: 2,
//       Ticker: 'EUR',
//       Name: 'Euro',
//       Type: 'Currency',
//       Priority: 1,
//       DCRCreationDate: null,
//       createdAt: '2021-08-27T17:55:57.883Z',
//       updatedAt: '2021-08-27T17:55:57.883Z'
//     }
//   }
// },

export const mapVasp = (items) => {
  if (items?.length > 0) {
    return items.map(item => ({
      name: item.Name,
      id: item.id,
    }));
  } else {
    return [];
  }
};
