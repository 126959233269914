import { handleFetching } from 'symbiote-fetching';

import { mapSymbolUsd } from 'features/wallet/mappers';
import { getClientProductPositions } from 'features/wallet/effects';
import { getResponse } from 'features/common/response';
import { AssetSymbols } from 'features/common/models';

import { actions as actionsNotifications } from '../notification/symbiotes';

import { actions } from './symbiotes';
import { clearPosition } from './models';
import { mapBalances, mapClient, mapSymbols, mapSymbolsMyAssets } from './mappers';
import { accountApi } from './api';

export const getClient = () =>
  handleFetching(actions.fetchClient, {
    noThrow: false,
    async run(dispatch) {
      try {
        const response = await dispatch(accountApi.getClient);
        const { ok, error, data } = getResponse(response);
        if (ok) {
          let client = mapClient(response);
          await dispatch(actions.setClient(client));
          await dispatch(actionsNotifications.setEventPush({ eventSended: false }));
        }
        return {
          ok,
          error,
          data,
        };
      } catch (error) {
        return { ok: false, error: { data: String(error) } };
      }
    },
  });

export const updateAccount = nokyc =>
  handleFetching(actions.fetchUpdateAccount, {
    noThrow: false,
    async run(dispatch) {
      try {
        const { ok, error, data } = await dispatch(getClientSymbolsMyAssets, nokyc);
        if (ok) {
          Promise.allSettled([
            dispatch(setDefaultPositions),
            dispatch(getClientProductPositions),
          ]);
        }
        return { ok, error, data };
      } catch (error) {
        return { ok: false, error: { data: String(error) } };
      }
    },
  });

export const checkPosition = query =>
  handleFetching(actions.fetchOne, {
    noThrow: false,
    async run(dispatch) {
      try {
        const response = await dispatch(accountApi.checkPosition, query);
        const { ok, error, data } = getResponse(response);
        if (ok) {
          if (query?.MaxPrice) {
            dispatch(actions.setPosition(response));
          } else {
            dispatch(actions.setPosition(response));
            dispatch(actions.setPositionCurrent(response));
          }
        }
        return { ok, error, data };
      } catch (error) {
        return { ok: false, error: { data: String(error) } };
      }
    },
  });

export const resetPosition = () =>
  handleFetching(actions.fetchOne, {
    noThrow: false,
    async run(dispatch) {
      try {
        dispatch(actions.setPosition(clearPosition));
        dispatch(actions.setPositionCurrent(clearPosition));
      } catch (error) {
        return { ok: false, error: { data: String(error) } };
      }
    },
  });

export const setDefaultPositions = query =>
  handleFetching(actions.fetchOne, {
    noThrow: false,
    async run(dispatch) {
      try {
        const response = await dispatch(accountApi.getPublicSymbols, { ...query, limit: 2000 });
        const { ok, error, data } = getResponse(response);
        if (ok) {
          let symbols = mapSymbols(response.rows);
          dispatch(actions.setSymbols(symbols));
        }
        return {
          ok,
          error,
          data,
        };
      } catch (error) {
        return { ok: false, error: { data: String(error) } };
      }
    },
  });

export const getClientPnL = () =>
  handleFetching(actions.fetchClientPnL, {
    noThrow: false,
    async run(dispatch) {
      try {
        const response = await dispatch(accountApi.getClientPnL);
        const { ok, error, data } = getResponse(response);
        if (ok) {
          await dispatch(actions.setClientPnL(response.source_data));
        }
        return {
          ok,
          error,
          data,
        };
      } catch (error) {
        return { ok: false, error: { data: String(error) } };
      }
    },
  });

export const getPublicClientPnL = () =>
  handleFetching(actions.fetchClientPnL, {
    noThrow: false,
    async run(dispatch) {
      try {
        const response = await dispatch(accountApi.getPublicClientPnL);
        const { ok, error, data } = getResponse(response);
        if (ok) {
          await dispatch(actions.setClientPnL(response.source_data));
        }
        return {
          ok,
          error,
          data,
        };
      } catch (error) {
        return { ok: false, error: { data: String(error) } };
      }
    },
  });

export const getClientSymbolsMyAssets = (nokyc = false, updating = false) =>
  handleFetching(!updating ? actions.fetchClientSymbolsMyAssets : actions.fetchUpdating, {
    noThrow: false,
    async run(dispatch) {
      try {
        const response = await dispatch(nokyc ? accountApi.getPublicSymbolsMyAssets : accountApi.getClientSymbolsMyAssets);
        const { ok, error, data } = getResponse(response);
        if (ok) {
          const symbolUsd = mapSymbolUsd(data.source_data.find(item => item.Ticker === AssetSymbols.USD) ?? {});
          let positions = mapSymbolsMyAssets(response.source_data);
          let balances = mapBalances(positions);
          let totalBalance = positions.reduce((acc, val) => acc + val.estimatedQty, 0);
          await dispatch(actions.setSymbolsMyAssets(positions));
          await dispatch(actions.setSymbolUsd(symbolUsd));
          await dispatch(actions.setBalances(balances));
          await dispatch(actions.setTotalBalance(totalBalance));
        }
        return {
          ok,
          error,
          data,
        };
      } catch (error) {
        return { ok: false, error: { data: String(error) } };
      }
    },
  });
