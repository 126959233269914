import { createSelector } from 'reselect';

import { authClientSelector } from '../auth/selectors';

export const accountRootSelector = createSelector(
  root => root.account,
  account => account.account
);

export const positionSelector = createSelector(accountRootSelector, account => account.position);

export const positionCurrentSelector = createSelector(accountRootSelector, account => account.positionCurrent);

export const clientSelector = createSelector(accountRootSelector, account => account.client);

export const clientIsTermsSelector = createSelector(accountRootSelector, account => account.client?.acceptedTermsCount >= 3);

export const kycReportSelector = createSelector(authClientSelector, client => client?.KycReport);

export const symbolsSelector = createSelector(accountRootSelector, account => account.symbols);

export const clientLoadingStatusSelector = createSelector(accountRootSelector, account => account?.fetchClient?.status);

export const clientPnLSelector = createSelector(accountRootSelector, account => account.clientPnL);

export const symbolsMyAssetsSelector = createSelector(accountRootSelector, account => account.symbolsMyAssets);
export const symbolUsdSelector = createSelector(accountRootSelector, account => account.symbolUsd);
export const balancesSelector = createSelector(accountRootSelector, account => account.balances);
export const totalBalanceSelector = createSelector(accountRootSelector, account => account.totalBalance);

export const symbolsMyAssetsLoadingStatusSelector = createSelector(accountRootSelector, account => account?.fetchClientSymbolsMyAssets?.status);
