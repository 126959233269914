import { createSelector } from 'reselect';

export const transactionsRootSelector = createSelector(
  root => root.account,
  account => account.transactions
);

export const transactionsSelector = createSelector(transactionsRootSelector, transactions => transactions.transactions);
export const transactionsTotalSelector = createSelector(transactionsRootSelector, transactions => transactions.transactionsTotal);

export const transactionsLoadingStatusSelector = createSelector(transactionsRootSelector, transactions => transactions?.fetchTransactions?.status);

export const filterTransactionsSelector = createSelector(transactionsRootSelector, transactions => transactions.filterTransactions);
export const paginationTransactionsSelector = createSelector(transactionsRootSelector, transactions => transactions.paginationTransactions);

export const refundPositionSelector = createSelector(transactionsRootSelector, transactions => transactions.refundPosition);
