import { api } from './../common/index';

export const addressesApi = {
  getWallets: query => api.get('/ui/crypto-wallet', query),
  getPublicWallets: query => api.get('/ui/nokyc/crypto-wallet', query),
  getWithdrawalAddress: query => api.get('/ui/withdrawal-address', query),
  getNokycWithdrawalAddress: query => api.get('/ui/nokyc/withdrawal-address', query),
  getWallet: id => api.get(`/ui/crypto-wallet/${id}`),
  getPublicWallet: id => api.get(`/ui/nokyc/crypto-wallet/${id}`),
  createWallet: query => api.post('/ui/crypto-wallet', query),
  updateWallet: (id, query) => api.put(`/ui/crypto-wallet/${id}`, query),
  updateWalletLabel: (id, name) => api.put(`/ui/crypto-wallet/${id}/name`, name),
  deleteWallet: (id, query) => api.del(`/ui/crypto-wallet/${id}`, query),
  getWalletTypes: () => api.get('/ui/crypto-wallet/types'),
  getPublicWalletTypes: () => api.get('/ui/nokyc/crypto-wallet/types'),
  getBankDetailWallets: query => api.get('/ui/bank-detail/client', query),
  getPublicBankDetailWallets: query => api.get('/ui/nokyc/bank-detail/client', query),
  createBankDetailItem: query => api.post('/ui/bank-detail/client', query),
  getClientBankDetailId: id => api.get(`/ui/bank-detail/client/${id}`),
  updateBankDetailItem: (id, query) => api.put(`/ui/bank-detail/client/${id}`, query),
  deleteBankDetailItem: (id, query) => api.del(`/ui/bank-detail/client/${id}`, query),
  getWithdrawalAddressStatuses: () => api.get('/ui/withdrawal-address/statuses'),
  getWithdrawalAddressSymbols: () => api.get('/ui/withdrawal-address/symbols'),
  getPublicWithdrawalAddressSymbols: () => api.get('/ui/nokyc/withdrawal-address/symbols'),
  getVasps: () => api.get('/ui/crypto-wallet/vasps'),
  getPublicVasps: () => api.get('/ui/nokyc/crypto-wallet/vasps'),

  sendTokenToConfirmationAddress: (typeConfirm, token) => api.get(`/confirm/${typeConfirm}/${token}`),
  resendTokenCryptoWallet: id => api.put(`/ui/crypto-wallet/${id}/resend-confirmation`),
  resendTokenBankDetails: id => api.put(`/ui/bank-detail/client/${id}/resend-confirmation`),
  getWithdrawalSettings: () => api.get('/ui/withdrawal-address/settings'),

  checkAndGetCryptoAdress: query => api.get('/ui/crypto-wallet/check-address', query),
  checkBankAdress: query => api.get('/ui/bank-detail/check-address', query),

  getProofScreen: link => api.get(`/files/wallets/${link}`),
};
