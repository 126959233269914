export const TransactionStatus = {
  COMPLETED: 'completed',
  PENDING_IN: 'pending_in',
  PENDING_OUT: 'pending_out',
  REFUND_PENDING: 'refund_pending',
  DECLINED: 'declined',
  REJECTED: 'rejected',
  CANCELED: 'canceled',
  REFUNDING: 'refunding',
  REFUNDED: 'refunded',
};

export const Directions = {
  BUY: 'buy',
  SELL: 'sell',
};

export const columnsAll = [
  {
    name: 'asset',
    title: 'Asset',
  },
  {
    name: 'quantity',
    title: 'Amount',
  },
  {
    name: 'type',
    title: 'Type',
  },
  {
    name: 'date',
    title: 'Date',
  },
  {
    name: 'status',
    title: 'Status',
  },
];

export const TransactionsReportStatus = {
  PENDING: 'pending',
  PROCESSING: 'processing',
  COMPLETED: 'completed',
  NOT_EXIST: 'not exist',
};
